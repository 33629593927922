import {pick} from 'lodash';
import {URLS, getEditUrl} from '../utils/urls';

const BOOKMARK_FOLDER = 'Pinboards';
const USE_OTHER_BOOKMARKS =
  !window?.chrome?.bookmarks?.rootName?.PINBOARD ||
  localStorage.getItem('use_other_bookmarks') === 'true';

async function getBookmarkRootID() {
  async function getOtherBookmarksRootID() {
    const root = await new Promise(resolve => {
      chrome.bookmarks.getRootByName(
        chrome.bookmarks.rootName.USER_ROOT,
        resolve,
      );
    });
    const others = await new Promise(resolve => {
      chrome.bookmarks.getChildren(root.id, resolve);
    });
    let pinboard = others.find(
      node => node.url === undefined && node.title === BOOKMARK_FOLDER,
    );
    if (!pinboard) {
      console.warn('A new bookmark folder will be created for Pinboards.');
      pinboard = await new Promise(resolve => {
        chrome.bookmarks.create(
          {parentId: root.id, title: BOOKMARK_FOLDER},
          resolve,
        );
      });
    }
    return pinboard.id;
  }

  async function getPinboardRootId() {
    const root = await new Promise(resolve => {
      chrome.bookmarks.getRootByName(
        chrome.bookmarks.rootName.PINBOARD,
        resolve,
      );
    });
    return root.id;
  }

  if (USE_OTHER_BOOKMARKS) {
    return await getOtherBookmarksRootID();
  }
  return await getPinboardRootId();
}

async function findDashboardBookmark() {
  const rootId = await getBookmarkRootID();
  return (
    await new Promise(resolve => chrome.bookmarks.getChildren(rootId, resolve))
  ).find(node => node.url === URLS.dashboard);
}

async function getDashboardMeta() {
  if (opr.pinboardPrivate.getUser) {
    return await new Promise(resolve => opr.pinboardPrivate.getUser(resolve));
  }
  let dashboard = await findDashboardBookmark();
  if (dashboard) {
    return await new Promise(resolve => {
      chrome.bookmarkManagerPrivate.getMetaInfo(
        dashboard.id,
        undefined,
        resolve,
      );
    });
  }
  return undefined;
}

async function updateDashboard(meta) {
  if (opr.pinboardPrivate.setUser) {
    opr.pinboardPrivate.setUser(meta);
    return;
  }
  const dashboard = await findDashboardBookmark();
  if (!dashboard) {
    const rootId = await getBookmarkRootID();
    await new Promise(resolve =>
      chrome.bookmarkManagerPrivate.createWithMetaInfo(
        {parentId: rootId, title: 'Dashboard', url: URLS.dashboard},
        meta,
        resolve,
      ),
    );
  } else {
    await new Promise(resolve =>
      chrome.bookmarkManagerPrivate.updateMetaInfo(dashboard.id, meta, resolve),
    );
  }
}

async function getPinboardMeta(id, token, node) {
  if (!node) {
    return null;
  }
  const meta = await new Promise(resolve => {
    chrome.bookmarkManagerPrivate.getMetaInfo(node.id, undefined, resolve);
  });
  return {
    token,
    id,
    thumb: meta?.thumb,
    cover: meta?.cover,
    name: node.title,
    uat: meta?.uat,
  };
}

async function getBookmark(id, token) {
  const rootId = await getBookmarkRootID();
  const nodes = (
    await new Promise(resolve => {
      chrome.bookmarks.search({url: getEditUrl(id, token)}, resolve);
    })
  ).filter(node => node.parentId === rootId);
  if (nodes.length < 1) {
    return null;
  }
  return nodes[0];
}

const Bookmark = {
  getRootID: getBookmarkRootID,
  getDashboardMeta: getDashboardMeta,
  updateDashboard: updateDashboard,
  async getAllPinboards() {
    if (opr.pinboardPrivate.getRemotePinboards) {
      return new Promise(resolve =>
        opr.pinboardPrivate.getRemotePinboards(resolve),
      );
    }
    const rootId = await getBookmarkRootID();
    let nodes = await new Promise(resolve => {
      chrome.bookmarks.getChildren(rootId, resolve);
    });
    return (
      await Promise.all(
        nodes.map(async node => {
          const matches = node.url.match(`${URLS.origin}/edit/(.*)/(.*)`);
          if (!matches) {
            return null;
          }
          return await getPinboardMeta(matches[1], matches[2], node);
        }),
      )
    ).filter(node => node !== null);
  },
  async getPinboard(id, token) {
    if (opr.pinboardPrivate.getRemotePinboard) {
      return await new Promise(resolve => {
        opr.pinboardPrivate.getRemotePinboard(id, token, resolve);
      });
    }
    return await getPinboardMeta(id, token, await getBookmark(id, token));
  },
  async savePinboard(id, token, {title, img}) {
    if (!id || !token) {
      return;
    }
    if (opr.pinboardPrivate.saveRemotePinboard) {
      opr.pinboardPrivate.saveRemotePinboard({
        id,
        token,
        name: title,
        lastModified: new Date().toISOString(),
        ...pick(img, ['thumb', 'cover']),
      });
      return;
    }
    const pinboardRootId = await getBookmarkRootID();
    const bookmarkNode = await new Promise(resolve => {
      chrome.bookmarks.create(
        {
          parentId: pinboardRootId,
          title,
          url: getEditUrl(id, token),
        },
        resolve,
      );
    });
    await this.updatePinboard(id, token, {uat: new Date(), img});
    return bookmarkNode?.url;
  },

  async updatePinboard(id, token, {uat, title, img}) {
    if (opr.pinboardPrivate.saveRemotePinboard) {
      const pinboard = {id, token, name: title};
      if (uat) {
        pinboard.lastModified = uat.toISOString();
      }
      if ('thumb' in (img ?? {})) {
        pinboard.thumb = img.thumb || '';
      }
      if ('cover' in (img ?? {})) {
        pinboard.cover = img.cover || '';
      }

      opr.pinboardPrivate.saveRemotePinboard(pinboard);
      return;
    }
    try {
      const nodeId = (await getBookmark(id, token))?.id;
      if (!nodeId) {
        return;
      }

      if (title) {
        await new Promise(resolve => {
          chrome.bookmarks.update(nodeId, {title}, resolve);
        });
      }

      const meta = {};
      if (uat) {
        meta.uat = uat.toISOString();
      }
      if ('thumb' in (img || {})) {
        meta.thumb = img.thumb;
      }
      if ('cover' in (img || {})) {
        meta.cover = img.cover;
      }

      await new Promise(resolve => {
        chrome.bookmarkManagerPrivate.updateMetaInfo(nodeId, meta, resolve);
      });

      for (const key in meta) {
        if (meta[key] !== null) {
          continue;
        }
        await new Promise(resolve =>
          chrome.bookmarkManagerPrivate.deleteMetaInfo(nodeId, key, resolve),
        );
      }
    } catch (error) {
      console.error(error);
    }
  },

  async deletePinboard(id, token) {
    if (opr.pinboardPrivate.deleteRemotePinboard) {
      opr.pinboardPrivate.deleteRemotePinboard({id, token});
      return;
    }
    const node = await getBookmark(id, token);
    if (node) {
      await new Promise(resolve => {
        chrome.bookmarkManagerPrivate.forceRemove(node.id, resolve);
      });
    }
  },

  async getLastVisitedTimes() {
    if (!opr.pinboardPrivate.getLastVisitedTimes) {
      return undefined;
    }
    return await new Promise(resolve =>
      opr.pinboardPrivate.getLastVisitedTimes(resolve),
    );
  },
};

export default Bookmark;
