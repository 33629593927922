import {Browser} from '../utils/consts';

export function isSupportedBrowser() {
  return !!window?.opr?.pinboardPrivate;
}

export async function getProduct() {
  if (isSupportedBrowser()) {
    if (opr?.operaBrowserPrivate?.getProduct) {
      return new Promise(resolve => {
        opr.operaBrowserPrivate.getProduct(product => {
          if (product === Browser.OPERA_GX) {
            resolve(product);
          } else {
            resolve(Browser.OPERA);
          }
        });
      });
    }
    return Browser.OPERA;
  }
  return Browser.OTHER;
}
