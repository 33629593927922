import {pickBy} from 'lodash';
import {calculateNextGroupPos} from './helper';

export const toUiPinboard = rawPinboard => {
  if (!rawPinboard) {
    return;
  }
  const uiPinboard = pickBy(
    rawPinboard,
    (_v, key) => !['items', 'groups'].includes(key),
  );
  const map = new Map();
  for (const item of rawPinboard.items) {
    const pos = item.pos.y;
    let group = map.get(pos);
    if (!group) {
      group = {
        pos: pos,
        items: [],
      };
      const groupData = rawPinboard.groups?.find(g => g.pos === group.pos);
      if (groupData) {
        group.id = groupData.id;
        group.title = groupData.title;
      }
      map.set(pos, group);
    }
    group.items.push({
      ...item,
    });
  }
  const groups = [...map.values()].sort((g1, g2) => g1.pos - g2.pos);
  groups.forEach(group => {
    group.items.sort((i1, i2) => i1.pos.x - i2.pos.x);
  });
  groups.push({
    pos: calculateNextGroupPos(groups),
    items: [],
    transient: true,
  });
  uiPinboard.groups = groups;
  return uiPinboard;
};
