import AWS from 'aws-sdk/global';
import {v4 as uuidV4} from 'uuid';
import AWSMqttClient from './aws-mqtt-client/aws-mqtt-client';

const config = {
  aws: {
    region: 'eu-central-1',
    iot: {
      endpoint: 'a1wos5q5js16o-ats.iot.eu-central-1.amazonaws.com',
    },
  },
};

AWS.config.region = 'eu-central-1';
const credentials = new AWS.CognitoIdentityCredentials({
  IdentityPoolId: 'eu-central-1:8a6ae95d-a1ff-4b90-98da-ca159731f4e0',
});

const TOPIC_REGEX = /^board\/(?<id>[0-9a-z-]+$)/;
// https://docs.aws.amazon.com/general/latest/gr/iot-core.html
const AWS_MQTT_MAX_THING_NAME_SIZE = 128;

function diagnosticDataToBeSentWithId() {
  if (!navigator.userAgentData) {
    return process.env.REACT_APP_VERSION;
  }
  return `${+navigator.userAgentData.mobile}${navigator.userAgentData.brands
    .map(b => `${b.brand.slice(0, 3)}${b.version}`)
    .reduce((p, c) => `${p}${c}`, '')}${navigator.userAgentData.platform.slice(
    0,
    3,
  )}${process.env.REACT_APP_VERSION}`;
}

class NotificationClient {
  #userId;
  #messageHandler;
  #mqttClient;
  #pinboards = {};

  constructor(userId, messageHandler) {
    this.#userId = userId;
    this.#messageHandler = messageHandler;

    const clientId = () =>
      `${(this.#userId ?? '').replace(/-/g, '')}${uuidV4().replace(
        /-/g,
        '',
      )}|${diagnosticDataToBeSentWithId()}`.slice(
        0,
        AWS_MQTT_MAX_THING_NAME_SIZE,
      );

    this.#mqttClient = new AWSMqttClient(
      config.aws.iot.endpoint,
      clientId,
      this._onMessage.bind(this),
      credentials,
    );
  }

  async subscribe(pinboardId) {
    if (pinboardId in this.#pinboards) {
      this.#pinboards[pinboardId]++;
      return true;
    }
    this.#pinboards[pinboardId] = 1;
    if (!(await this._subscribe(pinboardId))) {
      delete this.#pinboards[pinboardId];
      return false;
    }
    return true;
  }

  async unsubscribe(pinboardId) {
    this.#pinboards[pinboardId] = (this.#pinboards[pinboardId] || 0) - 1;
    if (this.#pinboards[pinboardId] <= 0) {
      await this.#mqttClient.unsubscribe(`board/${pinboardId}`);
      delete this.#pinboards[pinboardId];
    }
  }

  async _subscribe(pinboardId) {
    const topic = `board/${pinboardId}`;
    await this.#mqttClient.subscribe(topic);
    return true;
  }

  _onMessage(topic, message) {
    const id = topic.match(TOPIC_REGEX)?.groups?.id;
    if (id) {
      this.#messageHandler({
        id,
        message: JSON.parse(message),
      });
    }
  }
}

export default NotificationClient;
