import React from 'react';

import {URLS} from '../utils/urls';
import Bookmark from './bookmark';
import {isSupportedBrowser} from '../config/browser';

const USER_TOKEN_KEY = 'user_token';

const PINBOARDS_PAGE = '/';
const FEATURE_PAGE =
  'https://www.opera.com/features/pinboards?utm_medium=ose&utm_source=pinboards&utm_campaign=logo';
const DOWNLOAD_PAGE =
  'https://www.opera.com/computer/opera?utm_medium=ose&utm_source=pinboards&utm_campaign=makeacopy';
const GX_PAGE = 'https://www.opera.com/gx';

const THUMB_CACHE_PREFIX = 'thumb.';

export const UserContext = React.createContext(null);
export const EditableContext = React.createContext(false);

export function askImageFromUser() {
  return new Promise(resolve => {
    // We will get 'focus' event when the file selector is closed.
    // This might happen when either selecting a file or canceling the dialog.
    // If a file is selected, we want to handle 'change' event before 'focus'.
    // To assure proper processing order, let's handle Focus with a delay.
    let resolveTimeout = null;
    const handleFocus = () => {
      resolveTimeout = setTimeout(resolve, 3000);
    };
    window.addEventListener('focus', handleFocus, {once: true});
    const fileInput = document.createElement('input');
    fileInput.type = 'file';
    fileInput.accept = 'image/png, image/jpg, image/jpeg';
    fileInput.addEventListener('change', e => {
      clearTimeout(resolveTimeout);
      window.removeEventListener('focus', handleFocus);
      resolve(e.target.files?.[0]);
    });
    fileInput.click();
  });
}

export async function getUser() {
  let user = JSON.parse(window.localStorage.getItem(USER_TOKEN_KEY));
  if (isSupportedBrowser()) {
    const meta = await Bookmark.getDashboardMeta();
    if (!meta) {
      if (!!user) {
        console.warn('No user is found in dashboard bookmark. Set it up now.');
        await Bookmark.updateDashboard({
          userId: user.id,
          userToken: user.token,
        });
      }
    } else {
      if (user?.id === meta.userId && user?.token === meta.userToken) {
        return user;
      }
      if (!!user) {
        console.warn(
          'Found a different user in dashboard bookmark. ' +
            'This user will be used from now on.',
        );
      }
      user = {id: meta.userId, token: meta.userToken};
      window.localStorage.setItem(USER_TOKEN_KEY, JSON.stringify(user));
    }
  }
  return user;
}

export async function saveUser({id, token}) {
  if (!id || !token) {
    return;
  }
  if (isSupportedBrowser()) {
    await Bookmark.updateDashboard({userId: id, userToken: token});
  }
  window.localStorage.setItem(USER_TOKEN_KEY, JSON.stringify({id, token}));
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = React.useState(value);
  React.useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export function useBodyWidth() {
  const [bodyWidth, setBodyWidth] = React.useState(document.body.clientWidth);
  React.useEffect(() => {
    function handleResize() {
      setBodyWidth(document.body.clientWidth);
    }
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return bodyWidth;
}

export async function clearData() {
  if (isSupportedBrowser()) {
    if (!opr.pinboardPrivate.clearRemoteData) {
      const rootId = await Bookmark.getRootID();
      const promises = (
        await new Promise(resolve =>
          chrome.bookmarks.getChildren(rootId, resolve),
        )
      )
        .filter(node => node.url.startsWith(URLS.origin))
        .map(
          node =>
            new Promise(resolve => {
              chrome.bookmarks.remove(node.id, resolve);
            }),
        );
      await Promise.all(promises);
    } else {
      opr.pinboardPrivate.clearRemoteData();
    }
  }

  window.localStorage.clear();
}

// Get a snapshot of a tab.
// - tabId: The id of the given tab. The current tab is used if missing.
// - clearCache: If it should ignore cache and create a new screenshot.
export async function getSnapshot({tabId, clearCache = false}) {
  if (!tabId && !opr.pinboardPrivate.getSnapshot) {
    if (!chrome.tabs) {
      console.error('Unexpected broken client');
      return null;
    }
    tabId = await new Promise(resolve => chrome.tabs.getCurrent(resolve)).then(
      tab => tab.id,
    );
  }

  return await new Promise(resolve => {
    if (opr.pinboardPrivate.getSnapshot) {
      opr.pinboardPrivate.getSnapshot({tabId, clearCache}, resolve);
    } else {
      opr.pinboardPrivate.getThumbnail(tabId, clearCache, resolve);
    }
  }).then(image => (image?.url?.length ? image.url : null));
}

export async function cacheThumbnail(pinboardId) {
  try {
    if (!pinboardId) {
      return;
    }
    const imageUrl = await getSnapshot({clearCache: true});
    if (!imageUrl) {
      console.warn('Cannot create thumbnail');
      return;
    }
    window.localStorage.setItem(`${THUMB_CACHE_PREFIX}${pinboardId}`, imageUrl);
  } catch (e) {
    console.error('Failed to cache thumbnail');
  }
}

export function clearThumbnailCache(pinboardId) {
  window.localStorage.removeItem(`${THUMB_CACHE_PREFIX}${pinboardId}`);
}

export function clearAllThumbnailCache() {
  Object.keys(localStorage)
    .filter(key => key.startsWith(THUMB_CACHE_PREFIX))
    .forEach(key => localStorage.removeItem(key));
}

export function calculateNextGroupPos(groups) {
  if (groups.length) {
    return Math.floor(Math.max(...groups.map(group => group.pos)) + 1);
  }
  return 1;
}

export function openOperaPage(type) {
  switch (type) {
    case 'pinboards':
      window.open(PINBOARDS_PAGE, '_blank');
      break;
    case 'feature':
      window.open(FEATURE_PAGE, '_blank');
      break;
    case 'gx':
      window.open(GX_PAGE, '_blank');
      break;
    case 'download':
    default:
      window.open(DOWNLOAD_PAGE, '_blank');
  }
}

export function filterObjectsList(list, config) {
  const fields = config.fields || [];
  const search = config.search || '';

  const phrasePattern = /(-?)"([^"]*)"|([^ ]+)/g;
  const phrases = search?.match(phrasePattern);

  let phrasesInclude = new Set();
  let phrasesExclude = new Set();

  if (phrases?.length) {
    for (let phrase of phrases) {
      let exclude = false;
      if (phrase[0] === '-') {
        exclude = true;
        phrase = phrase.substring(1);
      }
      if (phrase[0] === '"' && phrase[0] === phrase[phrase.length - 1]) {
        phrase = phrase.substring(1).slice(0, -1);
      }
      phrase = phrase.toLowerCase();
      if (phrase) {
        if (exclude) {
          phrasesExclude.add(phrase);
        } else {
          phrasesInclude.add(phrase);
        }
      }
    }
  }
  if (!search || (!phrasesInclude.size && !phrasesExclude.size)) {
    return list;
  }
  const result = [];
  for (const obj of list) {
    let found = new Set();
    let excluded = false;
    for (const field of fields) {
      const value = obj?.[field]?.toLowerCase();
      if (value) {
        if (!excluded) {
          for (const excludePhrase of phrasesExclude) {
            if (String(value).indexOf(excludePhrase) !== -1) {
              excluded = true;
            }
          }
        }
        if (!excluded) {
          for (const includePhrase of phrasesInclude) {
            if (String(value).indexOf(includePhrase) !== -1) {
              found.add(includePhrase);
            }
          }
        }
      }
    }
    if (!excluded && found.size === phrasesInclude.size) {
      result.push(obj);
    }
  }
  return result;
}

export const CSORT = {
  az: 'az',
  lm: 'lm',
  lv: 'lv',
};
