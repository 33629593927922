import {compressImage, getFileFromDataUrl, isImageFile} from './file';
import normalizeItem from './normalizeItem';
import {isDataUrl, isValidWebUrl} from './urls';
import {NOT_AN_IMAGE} from './consts';

const RETRY_DELAYS = [100, 100, 1000, 1000, 10000, 10000, 60000];

class HttpError extends Error {
  constructor(status, response) {
    super(`${status}: ${response}`);
    this.name = 'HttpError';
    this.status = status;
    this.response = response;
  }
}

async function request(
  {url, method = 'GET', auth = null, body = null},
  retry = 0,
) {
  if (!url) {
    throw new Error('Invalid URL');
  }

  const res = await fetch(url, {
    method,
    headers: {
      'Content-Type': 'application/json',
      'Accept-Language': navigator.language,
      ...(!!auth && {Authorization: `Bearer ${auth}`}),
    },
    ...(!!body && {body: JSON.stringify(body)}),
  });

  const response = await res.text();
  if (!res.ok) {
    if (res.status >= 500) {
      const delay = RETRY_DELAYS[retry];
      if (delay) {
        return await new Promise(resolve => {
          setTimeout(() => {
            request({url, method, auth, body}, retry + 1).then(resolve);
          }, delay);
        });
      }
    }

    const error = new HttpError(res.status, response);
    console.error(error);
    throw error;
  }

  return !!response ? JSON.parse(response) : null;
}

const isImagePath = path => !!path?.startsWith('images/');

class RemoteAPI {
  #server;
  #product;
  #onPinboardListUpdated;

  set server(val) {
    this.#server = val;
  }

  set product(val) {
    this.#product = val;
  }

  set onPinboardListUpdated(handler) {
    this.#onPinboardListUpdated = handler;
  }

  async createUser() {
    try {
      return await request({
        url: `${this.#server}/users`,
        method: 'POST',
      });
    } catch (e) {
      return null;
    }
  }

  async createPinboard(userToken, name) {
    try {
      const res = await request({
        url: `${this.#server}/boards`,
        method: 'POST',
        auth: userToken,
        body: {name, product: this.#product},
      });
      this.#onPinboardListUpdated?.();
      return res;
    } catch (e) {
      return null;
    }
  }

  async getPinboard(pinboardId) {
    try {
      return await request({
        url: `${this.#server}/boards/${pinboardId}?reactions=0`,
        method: 'GET',
      });
    } catch (e) {
      return null;
    }
  }

  async updatePinboard(id, token, {name, img, theme}) {
    if (theme) {
      console.assert(typeof theme === 'string', 'Theme is invalid');
    }
    try {
      await request({
        url: `${this.#server}/boards`,
        method: 'PATCH',
        auth: token,
        body: {
          ...(name && {name}),
          ...(img && {img}),
          ...(theme && {theme}),
        },
      });
      this.#onPinboardListUpdated?.();
      return true;
    } catch (e) {
      return false;
    }
  }

  async deletePinboard(token) {
    try {
      await request({
        url: `${this.#server}/boards`,
        method: 'DELETE',
        auth: token,
      });
      this.#onPinboardListUpdated?.();
      return true;
    } catch (e) {
      return false;
    }
  }

  async clonePinboard(id, userToken, name) {
    try {
      const res = await request({
        url: `${this.#server}/clone/${id}`,
        method: 'POST',
        auth: userToken,
        body: {reactions: false, product: this.#product, name},
      });
      this.#onPinboardListUpdated?.();
      return res;
    } catch (e) {
      return null;
    }
  }

  async createItems(boardToken, newItems) {
    const items = [];
    const images = [];
    for (let newItem of newItems) {
      const item = normalizeItem(newItem);
      images.push(item.img);
      delete item.img;
      items.push(item);
    }

    try {
      const res = await request({
        url: `${this.#server}/items`,
        method: 'POST',
        auth: boardToken,
        body: {items: items},
      });
      if (res?.ids?.length !== items.length) {
        throw new Error(`Unexpected response: ${JSON.stringify(res)}`);
      }
      const itemsToUpdate = res.ids
        .map((id, index) => {
          if (!id || !images[index]) {
            return null;
          }
          const item = items[index];
          item.id = id;
          item.img = images[index];
          return item;
        })
        .filter(item => !!item);

      if (itemsToUpdate.length > 0) {
        await this.updateItems(boardToken, itemsToUpdate);
      }
      return res.ids;
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
    return [];
  }

  async updateItems(boardToken, items) {
    const itemsToUpdate = items.map(normalizeItem);
    for (const item of itemsToUpdate) {
      await this._uploadImageIfNeeded(boardToken, item);
    }
    try {
      await request({
        url: `${this.#server}/items`,
        method: 'PATCH',
        auth: boardToken,
        body: {items: itemsToUpdate},
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteItem(boardToken, itemId) {
    try {
      await request({
        url: `${this.#server}/items/${itemId}`,
        method: 'DELETE',
        auth: boardToken,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async createGroup(boardToken, groupData) {
    try {
      const res = await request({
        url: `${this.#server}/groups`,
        method: 'POST',
        auth: boardToken,
        body: {groups: [groupData]},
      });
      return res?.ids?.[0] ?? null;
    } catch (e) {
      return null;
    }
  }

  async updateGroup(boardToken, groupData) {
    try {
      await request({
        url: `${this.#server}/groups`,
        method: 'PATCH',
        auth: boardToken,
        body: {groups: [groupData]},
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteGroup(boardToken, groupId) {
    try {
      await request({
        url: `${this.#server}/groups/${groupId}`,
        method: 'DELETE',
        auth: boardToken,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async getReactions(pinboardId) {
    try {
      return await request({url: `${this.#server}/reactions/${pinboardId}`});
    } catch (e) {
      return {};
    }
  }

  async addReactions(pinboardId, userToken, reactions) {
    try {
      await request({
        url: `${this.#server}/reactions/${pinboardId}`,
        method: 'POST',
        auth: userToken,
        body: {reactions},
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async deleteReaction(pinboardId, itemId, userToken, emoji) {
    try {
      await request({
        url: `${this.#server}/reactions/${pinboardId}/${itemId}/${emoji}`,
        method: 'DELETE',
        auth: userToken,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  async uploadImage(boardToken, {itemId, type}, {file, dataUrl}) {
    if (!file && !!dataUrl) {
      file = await getFileFromDataUrl(dataUrl);
    }
    return await this._uploadImageFile(boardToken, {itemId, type}, file);
  }

  async _uploadImageFile(boardToken, {itemId, type}, file) {
    try {
      if (!isImageFile(file)) {
        throw new Error(NOT_AN_IMAGE);
      }
      if (itemId && type) {
        throw new Error('An image cannot have both itemId and type');
      }
      if (!itemId && !type) {
        throw new Error('An image must have either an itemId or a type');
      }

      const formData = await request({
        url: `${this.#server}/images${itemId ? `/${itemId}` : ''}`,
        method: 'POST',
        auth: boardToken,
        body: {
          extension: file.type.split('/')?.[1],
          ...(type && {type}),
        },
      });

      file = await compressImage(file, formData.maxContentSize);

      if (formData.expiresAt < Date.now()) {
        throw new Error('Presigned URL is expired');
      }

      let form = new FormData();
      for (const key in formData.fields) {
        form.append(key, formData.fields[key]);
      }
      form.append('Content-Type', file.type);
      form.append('file', file);

      // Use fetch directly since this is not a request to our backend but to a
      // S3 bucket.
      const url = new URL(formData.url);
      await fetch(url, {method: 'POST', body: form});
      return {
        path: formData.fields.Key,
      };
    } catch (e) {
      console.error(e);
      return {err: e.message};
    }
  }

  async _uploadImageIfNeeded(boardToken, item) {
    if (!item.img) {
      return;
    }
    if (isImagePath(item.img.src)) {
      return;
    } // uploaded already
    if (isValidWebUrl(item.img.src)) {
      console.warn('Image from random URL is not supported.', item.img.src);
      delete item.img;
      return;
    }
    if (!isDataUrl(item.img.src)) {
      delete item.img;
      return;
    }
    const res = await this._uploadImageFile(
      boardToken,
      {itemId: item.id},
      await getFileFromDataUrl(item.img.src),
    );
    if (res?.path) {
      item.img.src = res.path;
    } else {
      delete item.img;
    }
  }
}

export default new RemoteAPI();
