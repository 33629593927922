import React from 'react';

const Context = React.createContext();
export function useIsNarrowView() {
  return React.useContext(Context);
}

const IsNarrowViewContext = props => {
  const [isNarrowView, setIsNarrowView] = React.useState(false);
  const updateIsMobile = () => {
    setIsNarrowView(window.innerWidth < 600);
  };

  // Monitor to window resize event
  React.useEffect(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
    return () => window.removeEventListener('resize', updateIsMobile);
  }, []);

  // Toggle a body class according to isNarrowView
  React.useEffect(() => {
    document.body.classList.toggle('isNarrowView', isNarrowView);
  }, [isNarrowView]);

  return (
    <Context.Provider value={isNarrowView}>
      {props.children}
    </Context.Provider>
  );
};

export default IsNarrowViewContext;
