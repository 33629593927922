import imageCompression from 'browser-image-compression';
import {FAILED_TO_COMPRESS, NOT_AN_IMAGE} from './consts';
import {isDataUrl} from './urls';

export function isImageFile(file) {
  return file instanceof File && file.type.startsWith('image');
}

export async function getFileFromDataUrl(dataUrl) {
  if (!isDataUrl(dataUrl)) {
    return null;
  }
  const blob = await fetch(dataUrl).then(res => res.blob());
  return new File([blob], 'image', {type: blob.type});
}

export async function getDataUrlFromFile(file) {
  return await new Promise(resolve => {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        resolve(reader.result);
      },
      false,
    );
    reader.readAsDataURL(file);
  });
}

export async function compressImage(file, maxBytes = 2097152 /* 2MB */) {
  if (!isImageFile(file)) {
    throw new Error(NOT_AN_IMAGE);
  }

  if (maxBytes < file.size) {
    const options = {
      maxSizeMB: maxBytes / 1024 / 1024,
      useWebWorker: true,
      maxIteration: 20,
    };
    file = await imageCompression(file, options);
    if (maxBytes < file.size) {
      throw new Error(FAILED_TO_COMPRESS);
    }
  }
  return file;
}
