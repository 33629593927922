import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';

function Toast({msg, setMsg}) {
  return (
    <Snackbar
      anchorOrigin={{vertical: 'top', horizontal: 'center'}}
      open={!!msg}
      autoHideDuration={3000}
      message={msg}
      onClose={() => {
        setMsg(null);
      }}
    />
  );
}

const Context = React.createContext();
export function useToastContext() {
  return React.useContext(Context);
}

const ToastContext = props => {
  const [toast, setToast] = React.useState(null);

  return (
    <Context.Provider value={[toast, setToast]}>
      {props.children}
      <Toast msg={toast} setMsg={setToast} />
    </Context.Provider>
  );
};

export default ToastContext;
