const accentColor = 'var(--accent-color)';
const secondaryColor = 'var(--secondary-background-color)';
const inputBackgroundColor = 'var(--input-background-color)';
const inputBorderColor = 'var(--input-border-color)';
const buttonBackgroundHoverColor = 'var(--button-background-hover-color)';
const primaryFontColor = 'var(--primary-font-color)';
const secondaryFontColor = 'var(--secondary-font-color)';
const panelBorderColor = 'var(--panel-border-color)';

const secondaryButtonColor = 'var(--gx-secondary-button-color)';
const secondaryButtonHoverColor = 'var(--gx-secondary-button-hover-color)';
const secondaryButtonFocusColor = 'var(--gx-secondary-button-focus-color)';

export default {
  gx: true,
  palette: {
    background: {
      paper: secondaryColor,
      tile: 'transparent',
    },
    button: {
      secondary: {
        background: {
          normal: secondaryButtonColor,
          hovered: secondaryButtonHoverColor,
          pressed: secondaryButtonFocusColor,
        },
      },
    },
    input: {
      background: inputBackgroundColor,
      border: inputBorderColor,
    },
    list: {
      link: secondaryFontColor,
    },
    pinboardItem: {
      editMode: {
        border: 'none',
        boxShadow: 'none',
      },
    },
    pinboardsList: {
      backgroundColor: secondaryColor,
      buttonSelected: buttonBackgroundHoverColor,
    },
    separator: {
      hovered: 'var(--gx-highlight-color)',
    },
    emojiPicker: {
      border: panelBorderColor,
      background: secondaryColor,
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: secondaryColor,
        borderBottom: '1px solid var(--separator-color)',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: secondaryColor,
        border: `1px solid ${panelBorderColor}`,
      },
    },
    MuiListItem: {
      root: {
        backgroundColor: secondaryColor,
        color: 'black',
        '&$selected': {
          backgroundColor: `${buttonBackgroundHoverColor} !important`,
        },
        '&:hover': {
          backgroundColor: `${buttonBackgroundHoverColor} !important`,
        },
      },
    },
    MuiButtonGroup: {
      grouped: {
        '&:hover': {
          backgroundColor: `${buttonBackgroundHoverColor} !important`,
        },
      },
    },
    MuiDialog: {
      paper: {
        border: `1px solid ${panelBorderColor}`,
      },
    },
    MuiPaper: {
      outlined: {
        borderColor: inputBorderColor,
      },
      rounded: {
        borderRadius: '4px',
      },
    },
    MuiSelect: {
      outlined: {
        borderColor: inputBorderColor,
      },
    },
    MuiSvgIcon: {
      root: {
        fill: primaryFontColor,
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: `${inputBorderColor} !important`,
      },
    },
    MuiLink: {
      root: {
        color: accentColor,
      },
    },
    PinboardItem: {
      root: {
        borderWidth: 0,
      },
    },
    AddPinButton: {
      root: {
        borderWidth: 0,
      },
    },
  },
};
