const LocationState = Object.freeze({
  Local: Symbol('LOCAL'),
  Remote: Symbol('REMOTE'),
  Publishing: Symbol('PUBLISHING'),
  Unpublishing: Symbol('UNPUBLISHING'),
});

export default LocationState;

export function locationStateFromPublisherTask(task) {
  switch (task) {
    case 'PUBLISH':
      return LocationState.Publishing;
    case 'UNPUBLISH':
      return LocationState.Unpublishing;
    default:
      return;
  }
}

export function isOnGoingLocationState(locationState) {
  return (
    locationState === LocationState.Publishing ||
    locationState === LocationState.Unpublishing
  );
}
