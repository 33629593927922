import {cloneDeep} from 'lodash';
import {isValidWebUrl} from './urls';

export default function normalizeItem(item) {
  function removeEmptyNodes(item) {
    if (!item.title?.v) {
      delete item.title;
    }
    if (!item.desc?.v) {
      delete item.desc;
    }
    if (!item.img?.src) {
      delete item.img;
    }
    if (!item.link?.href) {
      delete item.link;
    }
  }
  function removeUnsupportedNodes(item) {
    const SUPPORTED_KEYS = ['id', 'pos', 'title', 'desc', 'img', 'link'];
    const keys = Object.keys(item);
    for (const key of keys) {
      if (!SUPPORTED_KEYS.includes(key)) {
        delete item[key];
      }
    }
  }
  function normalizeLink(item) {
    if (!item.link) {
      return;
    }
    if (!isValidWebUrl(item.link.href)) {
      delete item.link;
      return;
    }
    if (item.link.href !== decodeURIComponent(item.link.href)) {
      return;
    }

    item.link.href = encodeURI(item.link.href);
  }
  const clonedItem = cloneDeep(item);
  removeEmptyNodes(clonedItem);
  removeUnsupportedNodes(clonedItem);
  normalizeLink(clonedItem);
  return clonedItem;
}
