import {toUiPinboard} from './converter';
import {isSupportedBrowser, getProduct} from '../config/browser';
import {
  clearAllThumbnailCache,
  clearThumbnailCache,
  getUser,
  saveUser,
} from './helper';
import {getEditUrl} from '../utils/urls';
import {Browser} from '../utils/consts';
import LocationState, {
  isOnGoingLocationState,
  locationStateFromPublisherTask,
} from '../utils/locationState';
import Backend from './backend';

import {LOCAL_TOKEN} from '../utils/consts';
import RemoteAPI from '../utils/remoteApi';
import NotificationClient from '../utils/notification-client';
import Bookmark from './bookmark';
import WorkerClient from './workerClient';
import SharedWorker from 'worker-loader?{"worker":{"type":"SharedWorker","options":{"name":"pinboards-worker"}}}!../worker/worker.js';

function checkBrowser() {
  if (!isSupportedBrowser()) {
    throw new Error('Operation is not supported in non Opera browser');
  }
}

class Service {
  #worker = undefined;
  #localAPI = undefined;
  #remoteAPI = undefined;
  #publisher = undefined;
  #notification = undefined;
  #notificationListeners = [];
  #product = undefined;
  #user = undefined;
  #userListeners = [];
  #inited;

  constructor() {
    this.#inited = (async () => {
      await Backend.init();
      this._setProduct(await getProduct());
      this._setUser(await getUser());
      await this._initWorker();
    })();
  }

  _setProduct(product) {
    this.#product = product;
  }

  async checkIsGX() {
    await this.#inited;
    return this.isGX();
  }

  isGX() {
    return this.#product === Browser.OPERA_GX;
  }

  async _initWorker() {
    if (isSupportedBrowser()) {
      this.#worker = new WorkerClient(new SharedWorker(), {
        ping: () => true,
        getBackendServer: () => Backend.server,
        getBackendImageBaseUrl: () => Backend.image,
        getProductName: () => (this.isGX() ? 'gx' : 'desktop'),
        getUser: getUser,
        getPinboardBookmark: Bookmark.getPinboard.bind(Bookmark),
        savePinboardBookmark: Bookmark.savePinboard.bind(Bookmark),
        deletePinboardBookmark: Bookmark.deletePinboard.bind(Bookmark),
      });
      await this.#worker.init();
      this.#localAPI = this.#worker.commands.localAPI;
      this.#remoteAPI = this.#worker.commands.remoteAPI;
      this.#publisher = this.#worker.commands.publisher;
      this.#notification = this.#worker.commands.pinboardNotification;
      this.#worker.addEventListener(
        'ON_PINBOARD_NOTIFICATION',
        (event, notification) => this._onNotification(notification),
      );
      this.#worker.addEventListener(
        'ON_PINBOARD_UPDATED',
        (event, notification) => this._onNotification(notification),
      );
      this.addUserListener(this.#worker.commands.updateUser);
      window.addEventListener('unload', () => {
        this.#worker.commands.disconnect();
      });
    } else {
      RemoteAPI.server = Backend.server;
      RemoteAPI.product = this.isGX() ? 'gx' : 'desktop';
      this.#remoteAPI = RemoteAPI;
      this.#notification = new NotificationClient(
        this.#user?.id,
        this._onNotification.bind(this),
      );
    }
  }

  _setUser(newUser) {
    this.#user = newUser;
    for (const listener of this.#userListeners) {
      listener(this.#user);
    }
  }

  async _getValidUser() {
    await this.#inited;
    if (!this.#user) {
      const newUser = await this.#remoteAPI.createUser();
      if (newUser) {
        this._setUser(newUser);
        await saveUser(this.#user);
      }
    }
    return this.#user;
  }

  async _shouldCreateRemotePinboard() {
    const hasPinboardSyncAPI = !!opr.pinboardPrivate.isSyncEnabled;
    if (!hasPinboardSyncAPI) {
      return true;
    }
    return await new Promise(resolve => {
      opr.pinboardPrivate.isSyncEnabled(resolve);
    });
  }

  async isPinboardPopupRefreshEnabled() {
    if (!opr.pinboardPrivate.isPinboardPopupRefreshEnabled) {
      return false;
    }
    return new Promise(resolve => {
      opr.pinboardPrivate.isPinboardPopupRefreshEnabled(resolve);
    });
  }

  async addPublisherListener(listener) {
    checkBrowser();
    await this.#inited;
    this.#worker.addEventListener('ON_PUBLISHER_TASK_STARTED', listener);
    this.#worker.addEventListener('ON_PUBLISHER_TASK_ENDED', listener);
  }

  async removePublisherListener(listener) {
    checkBrowser();
    await this.#inited;
    this.#worker.removeEventListener('ON_PUBLISHER_TASK_STARTED', listener);
    this.#worker.removeEventListener('ON_PUBLISHER_TASK_ENDED', listener);
  }

  async subscribeToNotification(pinboardId, token, listener) {
    await this.#inited;
    if (this.#notificationListeners.includes(listener)) {
      return;
    }
    this.#notificationListeners.push(listener);
    if (token !== LOCAL_TOKEN) {
      this.#notification.subscribe(pinboardId);
    }
  }

  async unsubscribeFromNotification(pinboardId, token, listener) {
    await this.#inited;
    this.#notificationListeners = this.#notificationListeners.filter(
      l => l !== listener,
    );
    if (token !== LOCAL_TOKEN) {
      await this.#notification.unsubscribe(pinboardId);
    }
  }

  async addPinboardListListener(listener) {
    checkBrowser();
    await this.#inited;
    this.#worker.addEventListener('ON_PINBOARD_LIST_UPDATED', listener);
  }

  async removePinboardListListener(listener) {
    checkBrowser();
    await this.#inited;
    this.#worker.removeEventListener('ON_PINBOARD_LIST_UPDATED', listener);
  }

  _onNotification(notification) {
    for (const listener of this.#notificationListeners) {
      listener(notification);
    }
  }

  addUserListener(newListener) {
    if (this.#userListeners.includes(newListener)) {
      return;
    }
    this.#userListeners.push(newListener);
    this.getUser().then(newListener);
  }

  removeUserListener(listenerToRemove) {
    this.#userListeners = this.#userListeners.filter(
      listener => listener !== listenerToRemove,
    );
  }

  async getUser() {
    await this.#inited;
    return this.#user;
  }

  async getAllPinboardsDetailedData() {
    checkBrowser();
    if (!(await this._getValidUser())) {
      return [];
    }
    const pinboards = (await Bookmark.getAllPinboards()) || [];
    pinboards.push(...(await this.#localAPI.getAllPinboardsDetailedData()));
    const lastVisitedTimes = await Bookmark.getLastVisitedTimes();
    // calculate un-normalized data
    for (const pinboard of pinboards) {
      const cachedThumb = localStorage.getItem(`thumb.${pinboard.id}`);
      if (cachedThumb) {
        pinboard.thumb = cachedThumb;
        pinboard.hasCachedThumb = true;
      }
      pinboard.lastModified = Date.parse(
        pinboard.lastModified || pinboard.uat,
      );
      pinboard.lastVisited = 0;
      if (!lastVisitedTimes) {
        const boardUrl = getEditUrl(pinboard.id, pinboard.token);
        try {
          const visits = await new Promise(s =>
            chrome.history.getVisits({url: boardUrl}, s),
          );
          if (visits?.length) {
            pinboard.lastVisited =
              visits[visits.length - 1]?.visitTime;
          }
        } catch (err) {
          // ignore
        }
      } else {
        pinboard.lastVisited = Date.parse(lastVisitedTimes[pinboard.id]) || 0;
      }
      pinboard.locationState =
        pinboard.token === LOCAL_TOKEN
          ? LocationState.Local
          : LocationState.Remote;
      const progress = await this.#localAPI.getPublisherProgress(pinboard.id);
      pinboard.locationState =
        locationStateFromPublisherTask(progress?._task) ||
        pinboard.locationState;
    }
    pinboards.sort((a, b) => a.lastModified - b.lastModified);
    return pinboards;
  }

  async createPinboard(name) {
    checkBrowser();
    if (!name) {
      const PINBOARD_NAME_REGEXP = /^Pinboard (\d+)$/;
      const index = Math.max(
        ...(await this.getAllPinboardsDetailedData())
          .map(pinboard => pinboard.name.match(PINBOARD_NAME_REGEXP))
          .filter(matched => !!matched)
          .map(matched => parseInt(matched[1], 10)),
      );
      name = `Pinboard ${(Number.isInteger(index) ? index : 0) + 1}`;
    }
    await this.#inited;
    const user = await this._getValidUser();
    if (!user) {
      return;
    }

    let res;
    if (await this._shouldCreateRemotePinboard()) {
      res = await this.#remoteAPI.createPinboard(user.token, name);
      await Bookmark.savePinboard(res?.id, res?.token, {title: name});
    } else {
      res = await this.#localAPI.createPinboard(name);
    }
    if (!res?.id || !res?.token) {
      return;
    }
    await this._showSidebarIconForFirstPinboard();
    return res;
  }

  async getPinboard(id, token) {
    await this.#inited;
    const rawPinboard =
      isSupportedBrowser() && token === LOCAL_TOKEN
        ? await this.#localAPI.getPinboard(id)
        : await this.#remoteAPI.getPinboard(id);
    return toUiPinboard(rawPinboard);
  }

  async updatePinboard(id, token, {name, img, theme}) {
    checkBrowser();
    await this.#inited;
    if (token === LOCAL_TOKEN) {
      return await this.#localAPI.updatePinboard(id, {name, img, theme});
    }
    const res = await this.#remoteAPI.updatePinboard(id, token, {
      name,
      img,
      theme,
    });
    if (res) {
      await Bookmark.updatePinboard(id, token, {
        uat: new Date(),
        title: name,
        img,
      });
    }
    return res;
  }

  async deletePinboard(id, token) {
    checkBrowser();
    await this.#inited;
    if (token === LOCAL_TOKEN) {
      await this.#localAPI.deletePinboard(id);
      return;
    }
    await Bookmark.deletePinboard(id, token);
    if (!(await this.#remoteAPI.deletePinboard(token))) {
      console.warn('Failed to remove pinboard from server');
    }
  }

  async clonePinboard(id, oldDetails) {
    checkBrowser();
    await this.#inited;
    const user = await this._getValidUser();
    if (!user) {
      return null;
    }
    const newName = `(clone) ${oldDetails.name}`;

    const localBoard = await this.#localAPI.getPinboard(id);
    if (localBoard) {
      return await this.#localAPI.clonePinboard(id, newName);
    }

    if (await this._shouldCreateRemotePinboard()) {
      return this._cloneRemotePinboardToRemote(user, id, newName, oldDetails);
    }

    return this._cloneRemotePinboardToLocal(user, id, newName, oldDetails);
  }

  async _cloneRemotePinboardToLocal(user, id, newName, oldDetails) {
    return await this.#publisher.clonePinboard(id, newName);
  }

  async _cloneRemotePinboardToRemote(user, id, newName, oldDetails) {
    const res = await this.#remoteAPI.clonePinboard(id, user.token, newName);
    if (!res) {
      return null;
    }
    const details = {title: newName};
    if (oldDetails.img) {
      details.img = {
        thumb: oldDetails.img.thumb,
        cover: oldDetails.img.cover,
      };
      details.img.thumb = details.img.thumb?.replace(id, res.id);
      details.img.cover = details.img.cover?.replace(id, res.id);
    }

    const url = await Bookmark.savePinboard(res.id, res.token, details);
    return {id: res.id, url};
  }

  async getReactions(pinboardId, token) {
    return isSupportedBrowser() && token === LOCAL_TOKEN
      ? await this.#localAPI.getReactions(pinboardId)
      : await this.#remoteAPI.getReactions(pinboardId);
  }

  async addReaction(pinboardId, token, itemId, emoji) {
    await this.#inited;
    const user = await this._getValidUser();
    if (!user) {
      return;
    }
    const reactions = [{id: itemId, react: emoji}];
    return isSupportedBrowser() && token === LOCAL_TOKEN
      ? await this.#localAPI.addReactions(pinboardId, user.id, reactions)
      : await this.#remoteAPI.addReactions(pinboardId, user.token, reactions);
  }

  async deleteReaction(pinboardId, token, itemId, emoji) {
    await this.#inited;
    const user = await this._getValidUser();
    if (!user) {
      return;
    }
    return isSupportedBrowser() && token === LOCAL_TOKEN
      ? await this.#localAPI.deleteReaction(pinboardId, itemId, user.id, emoji)
      : await this.#remoteAPI.deleteReaction(
          pinboardId,
          itemId,
          user.token,
          emoji,
        );
  }

  async createItems(pinboardId, pinboardToken, newItems) {
    checkBrowser();
    await this.#inited;
    for (let index = 0; index < newItems.length; index++) {
      if (newItems[index].id) {
        delete newItems[index].id;
      }
    }

    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.createItems(pinboardId, newItems);
    }

    const res = await this.#remoteAPI.createItems(pinboardToken, newItems);
    if (res?.length > 0) {
      await Bookmark.updatePinboard(pinboardId, pinboardToken, {
        uat: new Date(),
      });
    }
    return res;
  }

  async createItem(pinboardId, pinboardToken, newItem) {
    const res = await this.createItems(pinboardId, pinboardToken, [newItem]);
    return res.length === 1 ? {id: res[0]} : null;
  }

  async updateItems(pinboardId, pinboardToken, items) {
    checkBrowser();
    await this.#inited;
    if (!items?.every(item => !!item.id)) {
      return false;
    }
    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.updateItems(pinboardId, items);
    }
    const res = await this.#remoteAPI.updateItems(pinboardToken, items);
    if (res) {
      await Bookmark.updatePinboard(pinboardId, pinboardToken, {
        uat: new Date(),
      });
    }
    return res;
  }

  async updateItem(pinboardId, pinboardToken, item) {
    return this.updateItems(pinboardId, pinboardToken, [item]);
  }

  async deleteItem(pinboardId, pinboardToken, itemId) {
    checkBrowser();
    await this.#inited;
    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.deleteItem(pinboardId, itemId);
    }
    const res = await this.#remoteAPI.deleteItem(pinboardToken, itemId);
    if (res) {
      await Bookmark.updatePinboard(pinboardId, pinboardToken, {
        uat: new Date(),
      });
    }
    return res;
  }

  async createGroup(pinboardId, pinboardToken, data) {
    checkBrowser();
    await this.#inited;
    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.createGroup(pinboardId, data);
    }
    return await this.#remoteAPI.createGroup(pinboardToken, data);
  }

  async updateGroup(pinboardId, pinboardToken, data) {
    checkBrowser();
    await this.#inited;
    if (!data?.id) {
      return true;
    }

    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.updateGroup(pinboardId, data);
    }
    return await this.#remoteAPI.updateGroup(pinboardToken, data);
  }

  async deleteGroup(pinboardId, pinboardToken, groupId) {
    checkBrowser();
    await this.#inited;
    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.deleteGroup(pinboardId, groupId);
    }
    return await this.#remoteAPI.deleteGroup(pinboardToken, groupId);
  }

  async uploadImage(pinboardToken, target, image) {
    checkBrowser();
    await this.#inited;
    if (pinboardToken === LOCAL_TOKEN) {
      return await this.#localAPI.getImageDataUrl(image);
    }
    return await this.#remoteAPI.uploadImage(pinboardToken, target, image);
  }

  async publishPinboard(pinboardId, token, progressCallback) {
    checkBrowser();
    if (!(await this._getValidUser())) {
      return;
    }
    return await this.#publisher.publishPinboard(pinboardId, token, [
      progressCallback,
    ]);
  }

  async unpublishPinboard(pinboardId, token, progressCallback) {
    checkBrowser();
    if (!(await this._getValidUser())) {
      return;
    }
    return await this.#publisher.unpublishPinboard(pinboardId, token, [
      progressCallback,
    ]);
  }

  async cancelPublisherTask(pinboardId) {
    checkBrowser();
    return await this.#publisher.cancelTask(pinboardId);
  }

  async hasPublisherProgress(pinboardId) {
    checkBrowser();
    return await this.#publisher.hasProgress(pinboardId);
  }

  async updateThumbnailsAndClearCache(pinboards) {
    checkBrowser();
    await Promise.all(
      await pinboards
        .filter(
          pinboard =>
            pinboard.hasCachedThumb &&
            pinboard.thumb?.startsWith('data') &&
            !isOnGoingLocationState(pinboard.locationState),
        )
        .map(async pinboard => {
          const {id, token, thumb} = pinboard;
          const res = await this.uploadImage(
            token,
            {type: 'thumb'},
            {dataUrl: thumb},
          );
          if (!res?.path) {
            return;
          }
          await this.updatePinboard(id, token, {
            img: {thumb: res.path},
          });
          clearThumbnailCache(id);
        }),
    );
    clearAllThumbnailCache();
  }

  async _showSidebarIconForFirstPinboard() {
    const KEY_SIDEBAR_FIRST_PINBOARD = 'sidebar.shownForFirstPinboard';
    const PREF_ID = 'Pinboards';

    if (!opr?.browserSidebarPrivate) {
      return;
    }
    if (window.localStorage.getItem(KEY_SIDEBAR_FIRST_PINBOARD)) {
      return;
    }

    const pinboards = await this.getAllPinboardsDetailedData();
    if (pinboards.length === 1) {
      opr.browserSidebarPrivate.setItemVisibleUsingPrefId(PREF_ID, true);
      opr.browserSidebarPrivate.setShouldShowNotificationUsingPrefId(
        PREF_ID,
        opr.browserSidebarPrivate.NotificationVisibility
          .SHOW_AND_HIDE_AUTOMATICALLY_ON_ACTION,
      );
    }

    window.localStorage.setItem(KEY_SIDEBAR_FIRST_PINBOARD, 'true');
  }
}

export default new Service();
