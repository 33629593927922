export default {
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
    ].join(','),
    h1: {
      fontSize: '4rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '3rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h5: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    h6: {
      fontSize: '1rem',
      fontWeight: 600,
    },
  },
  styles: {
    PinboardContainer: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      marginTop: 'var(--pinboard-navigation-height)',
      maxWidth: 'none',
      minHeight: 'calc(100% - var(--pinboard-navigation-height) - 12px)',
      padding: '12px 0 0',
    },
  },
  overrides: {
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      containedPrimary: {
        fontSize: '13px',
        lineHeight: '18px',
        boxShadow: 'none',
        padding: '7px 10px',
        borderRadius: '3px',
        border: '1px solid',
        '&$disabled': {
          borderColor: 'transparent',
        },
      },
    },
    MuiIconButton: {
      root: {
        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiPaper: {
      elevation24: {
        boxShadow: 'none',
      },
      rounded: {
        borderRadius: '8px',
      },
    },
    MuiBackdrop: {
      root: {
        backgroundColor: 'var(--overlay-color)',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&$focused $notchedOutline': {
          borderWidth: '1px',
        },
      },
    },
    MuiSelect: {
      select: {
        backgroundColor: 'transparent',
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        fontSize: '14px',
        lineHeight: '16px',
        padding: '16px',
        minWidth: 'min-content !important',
      },
    },
    MuiTab: {
      wrapper: {
        textTransform: 'none',
        fontWeight: 600,
      },
    },
    MuiToolbar: {
      root: {
        height: 72,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 0,
        fontSize: '12px',
        lineHeight: '20px',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'black',
        fontSize: '12px',
        padding: '0 24px',
      },
    },
    PinboardGroup: undefined,
    PinboardGroupDropTarget: {
      dropTargetPrev: {
        '&::before': {
          content: '""',
          display: 'block',
          backgroundColor: 'var(--accent-color)',
          borderRadius: '4px',
          width: '4px',
        },
      },
      dropTargetNext: {
        '&::after': {
          content: '""',
          display: 'block',
          backgroundColor: 'var(--accent-color)',
          borderRadius: '4px',
          width: '4px',
        },
      },
    },
    PinboardItem: {
      root: {
        width: '264px',
      },
      others: {
        container: {
          margin: '12px',
          height: '100%',
          position: 'relative',
        },
      },
    },
    PinboardVerticalScroll: {
      root: {
        height: 10,
        left: '0px',
        position: 'fixed',
        width: '100%',
        zIndex: '1000',
      },
      scrollDown: {
        bottom: 0,
      },
      scrollUp: {
        top: 0,
        height: '74px',
      },
    },
    AddPinButton: {
      root: {
        fontWeight: 400,
        height: '60px',
        width: '264px',
        margin: '12px',
      },
      others: {},
    },
  },
  props: {
    MuiAppBar: {
      color: 'inherit',
    },
    MuiTooltip: {
      arrow: true,
    },
    MuiButtonBase: {
      disableRipple: true,
    },
    MuiTabs: {
      indicatorColor: 'primary',
    },
    PinboardGroupDragItem: {
      opacity: 0.7,
    },
    PinboardItem: {
      elevation: 8,
      Card: {
        elevation: 8,
        variant: 'elevation',
        square: false,
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  others: {
    PinboardItem: {
      multiColumn: false,
    },
    PinboardGroup: {
      addItemDoubleClickDirection: 'x',
      needsSpacer: true,
    },
  },
};

const setGxColors = async () => {
  const setVariable = (name, value) =>
    document.documentElement.style.setProperty(`--gx-${name}`, value);
  const getColor = (name, opacity = 1) =>
    new Promise(resolve => {
      opr.palette.getColorHSL(name, clr => {
        const s = Math.floor(clr.s * 100);
        const l = Math.floor(clr.l * 100);
        resolve(`hsla(${clr.h}, ${s}%, ${l}%, ${clr.alpha * opacity})`);
      });
    });
  const isDarkFont = await new Promise(resolve => {
    opr.palette.getColor('gx_accent', clr => {
      // http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
      const getLuminance = () => {
        const rgb = [clr.r, clr.g, clr.b].map(c => {
          let cs = c / 255;
          return cs <= 0.03928
            ? cs / 12.92
            : Math.pow((cs + 0.055) / 1.055, 2.4);
        });
        return 0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2];
      };
      resolve(getLuminance() > 0.45);
    });
  });
  const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;

  const accentColor = await getColor('gx_accent');
  setVariable('accent-color', accentColor);
  const highlightColor = await getColor('gx_accent', 0.5);
  setVariable('highlight-color', highlightColor);
  setVariable('primary-button-font-color', isDarkFont ? 'black' : 'white');

  const primaryFontColor = await getColor(
    isDarkTheme ? 'gx_no_100' : 'gx_no_00',
  );
  setVariable('primary-font-color', primaryFontColor);
  const secondaryFontColor = await getColor(
    isDarkTheme ? 'gx_no_77' : 'gx_no_24',
  );
  setVariable('secondary-font-color', secondaryFontColor);

  setVariable('primary-button-color', accentColor);
  const disabledButtonBackgroundColor = await getColor(
    isDarkTheme ? 'gx_no_16' : 'gx_no_88',
    0.5,
  );
  setVariable('primary-button-disabled-color', disabledButtonBackgroundColor);
  setVariable('primary-button-hover-color', accentColor);
  setVariable('primary-button-focus-color', accentColor);

  const secondaryButtonColor = await getColor(
    isDarkTheme ? 'gx_no_12' : 'gx_no_98',
  );
  setVariable('secondary-button-color', secondaryButtonColor);
  const secondaryButtonActionColor = await getColor(
    isDarkTheme ? 'gx_no_08' : 'gx_no_92',
  );
  setVariable('secondary-button-hover-color', secondaryButtonActionColor);
  setVariable('secondary-button-focus-color', secondaryButtonActionColor);

  const primaryBackgroundColor = await getColor(
    isDarkTheme ? 'gx_no_08' : 'gx_no_96',
  );
  setVariable('primary-background-color', primaryBackgroundColor);
  const secondaryBackgroundColor = await getColor(
    isDarkTheme ? 'gx_no_12' : 'gx_no_98',
  );
  setVariable('secondary-background-color', secondaryBackgroundColor);

  const inputBackgroundColor = await getColor(
    isDarkTheme ? 'gx_no_16' : 'gx_no_88',
  );
  setVariable('input-background-color', inputBackgroundColor);
  const inputBorderColor = await getColor(
    isDarkTheme ? 'gx_no_32' : 'gx_no_80',
  );
  setVariable('input-border-color', inputBorderColor);

  const buttonBackgroundColor = await getColor(
    isDarkTheme ? 'gx_no_20' : 'gx_no_90',
  );
  setVariable('button-background-color', buttonBackgroundColor);
  const buttonBackgroundHoverColor = await getColor(
    isDarkTheme ? 'gx_no_32' : 'gx_no_80',
  );
  setVariable('button-background-hover-color', buttonBackgroundHoverColor);
  const buttonBackgroundFocusColor = await getColor(
    isDarkTheme ? 'gx_no_59' : 'gx_no_40',
  );
  setVariable('button-background-focus-color', buttonBackgroundFocusColor);

  const separatorColor = await getColor(isDarkTheme ? 'gx_no_32' : 'gx_no_80');
  setVariable('separator-color', separatorColor);
};

export const setPaletteColors = isGx => {
  if (isGx) {
    setGxColors();
    if (!opr.palette.onPaletteChanged.hasListener(setGxColors)) {
      opr.palette.onPaletteChanged.addListener(setGxColors);
    }
  }
};
