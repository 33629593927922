import React from 'react';
import styles from './CircularProgress.module.css';

export default function CircularProgress(props: {width: string}) {
  return (
    <svg
      role="progressbar"
      className={styles.annimatedCircle}
      style={{'--size': props.width ?? '30px'} as React.CSSProperties}
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 7.50028C14 11.0901 11.0899 14.0003 7.5 14.0003C3.91015 14.0003 1 11.0901 1 7.50028C1 5.66841 1.75779 4.01354 2.977 2.83203"
        stroke="var(--circular-progress-outer-circle)"
        strokeLinecap="round"
      />
    </svg>
  );
}
