import pickerArrow from './arrowDark.png';

const primaryButtonColor = 'var(--primary-button-color)';
const primaryButtonHoverColor = 'var(--primary-button-hover-color)';
const primaryButtonFocusColor = 'var(--primary-button-focus-color)';
const primaryButtonDisabledColor = 'var(--primary-button-disabled-color)';
const primaryButtonFontColor = 'var(--primary-button-font-color)';

export default {
  palette: {
    type: 'dark',
    primary: {
      main: '#96DEFF',
    },
    secondary: {
      main: '#1c1d1f',
    },
    error: {
      main: '#7d0f27',
    },
    background: {
      default: '#1c1d1f',
      paper: '#2a343d',
      tile: '#2a343d',
    },
    text: {
      primary: '#ffffff',
    },
    divider: '#3e4852',
    operaBlue: primaryButtonColor,
    footer: {
      link: 'white',
    },
    action: {
      active: '#828385',
      hover: '#585859',
      selected: '#4a4b4b',
    },
    input: {
      background: 'rgba(42, 52, 61)',
      border: '#3e4852',
    },
    list: {
      link: '#666667',
      subTitle: '#ffffff',
    },
    pinboardsList: {
      buttonSelected: '#3E4852',
      dateColor: '#BCC4CC',
      backgroundColor: '#2A343D',
      backgroundColorHover: '#222325',
      borderColor: '#3E4852',
    },
    pinboardPopup: {
      checkmarkIconDisabled: '#3E4852',
      selectedPinboard: 'var(--selected-pinboard-color)',
    },
    separator: {
      default: 'rgba(255, 255, 255, 0.15)',
      hovered: 'rgba(255, 255, 255, 0.35)',
    },
    spinner: {
      defaultColor: primaryButtonColor,
      thumbnailColor: primaryButtonColor,
    },
    button: {
      addPinboard: {
        pressed: 'rgba(12, 37, 56, 1)',
        hovered: 'rgba(15, 27, 34, 1)',
      },
      primary: {
        foreground: '#000000',
        foregroundDisabled: '#777a7c',
        background: {
          normal: primaryButtonColor,
          hovered: primaryButtonHoverColor,
          pressed: primaryButtonFocusColor,
          disabled: primaryButtonDisabledColor,
        },
      },
      secondary: {
        foreground: '#ced1d2',
        background: {
          hovered: 'rgba(148, 221, 255, 0.24)',
          normal: '#2a343d',
          pressed: 'rgba(148, 221, 255, 0.14)',
        },
      },
      border: {
        normal: '#3e4852',
        hovered: '#3e4852',
        pressed: '#3e4852',
      },
      download: {
        background: {
          normal: primaryButtonColor,
          hovered: primaryButtonHoverColor,
        },
      },
    },
    emojiPicker: {
      background: 'rgba(18, 21, 23)',
      border: 'rgba(47, 55, 63)',
      emojiPickerArrow: pickerArrow,
    },
    pinboardCard: {
      shadow: '-2px 4px 16px rgba(25, 47, 77, 0.15)',
      foreground: '#ffffff',
      background: '#1c1d1f',
      border: '#2a343d',
    },
    pinboardItem: {
      editMode: {
        border: `2px solid ${primaryButtonColor}`,
        boxShadow: '-2px 4px 16px rgba(0, 0, 0, 0.4)',
      },
    },
    themeSelector: {
      header: '#ffffff',
    },
    thumbnailCard: {
      background: primaryButtonDisabledColor,
      button: {
        hovered: 'rgba(148, 221, 255, 0.24)',
      },
      shadow: '1px 3px 8px 4px hsla(0, 0%, 10%, .125)',
    },
    toolbarButton: {
      label: {
        color: '#ced1d2',
      },
    },
    Example: {
      header: '#3e4852',
    },
  },
  typography: {
    allVariants: {
      color: '#ffffff',
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#2a343d',
      },
      root: {
        boxShadow: '0px 4px 5px rgba(61, 52, 61, 0.1)',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: primaryButtonFontColor,
        backgroundColor: primaryButtonColor,
        borderColor: primaryButtonHoverColor,
        '&:hover': {
          backgroundColor: primaryButtonHoverColor,
        },
      },
      containedSecondary: {
        color: '#fafafa',
        backgroundColor: '#2a343d',
        borderColor: '#3e4852',
        borderWidth: '1px',
        '&:hover': {
          backgroundColor: '#3e4852',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#2A343D',
        '& $notchedOutline': {
          borderColor: '#3E4852',
        },
        '&:hover $notchedOutline': {
          borderColor: '#3E4852',
        },
        '&$focused $notchedOutline': {
          borderColor: '#3E4852',
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 4px 5px rgba(38, 49, 64, 0.4)',
      },
      elevation6: {
        boxShadow:
          '0px 8px 12px rgba(40, 42, 44, 0.14), 0px 4px 16px rgba(40, 42, 44, 0.16), 0px 4px 6px rgba(40, 42, 44, 0.2)',
      },
      elevation8: {
        boxShadow: '-2px 4px 16px rgba(25, 47, 77, 0.15)',
      },
      root: {
        background: '#1c1d1f',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#3E4852',
        color: '#ffffff',
        border: '1px solid #3E4852',
        boxShadow: '0px 10px 20px -8px rgba(0, 0, 0, 0.4)',
      },
    },
    MuiMenu: {
      paper: {
        background: '#1c1d1f',
        border: '0.5px solid #3e4852',
        boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.05)',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'white',
      },
    },
    MuiNativeSelect: {
      icon: {
        color: 'white',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#ced1d2',
      },
    },
    MuiCheckbox: {
      indeterminate: {
        '& .MuiSvgIcon-root': {
          fill: `${primaryButtonColor} !important`,
        },
      },
      colorPrimary: {
        '&:not($checked) .MuiSvgIcon-root': {
          fill: '#3e4852',
        },
        '&$checked .MuiSvgIcon-root': {
          fill: primaryButtonColor,
        },
      },
    },
  },
  props: {
    MuiTabs: {
      indicatorColor: '#ffffff',
    },
  },
};
