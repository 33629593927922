import React from 'react';

import CircularProgress from '../common/components/CircularProgress';

export default function PendingOverlay({
  size = 40,
  borderRadius = '8px',
  ...props
}) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'var(--overlay-color)',
        overflow: 'hidden',
        borderRadius,
        zIndex: '99',
        ...props,
      }}
    >
      <CircularProgress width={`${size}px`} />
    </div>
  );
}
