/* eslint-disable camelcase */
import {createBrowserHistory} from 'history';
import StatsService from './stats';

const history = createBrowserHistory();

function onRouteChanged(location, action) {
    const view = location?.pathname?.match(/\/[^/]*/)?.[0];
    if (view) {
      StatsService.recordSessionEvent('event_ab_test_data', {
        test_name: 'PinboardStats',
        data_type_id: `route[${view}]:${action}`,
      });
    }
}
history.listen(onRouteChanged);
onRouteChanged(history.location, history.action);

export default history;
