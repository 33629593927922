import {isNumber, isArray, set, last, isFunction} from 'lodash';
import {v4 as uuidV4} from 'uuid';
export default class WorkerClient {
  #worker;
  #clientAPIs;
  #debug;
  #commands = [];
  #events = [];
  #clientCalls = {};
  #listeners = {};
  #initPromise;
  #inited;

  constructor(worker, clientAPIs, debug = false) {
    this.#worker = worker;
    this.#clientAPIs = clientAPIs;
    this.#debug = debug;
    this.#initPromise = new Promise(resolve => {
      this.#inited = resolve;
      this.#worker.port.onmessage = this._handleMessage.bind(this);
    });
  }

  get commands() {
    return this.#commands;
  }

  get events() {
    return this.#events;
  }

  async init() {
    await this.#initPromise;
  }

  addEventListener(event, listener) {
    if (
      this.#events.includes(event) &&
      !this.#listeners[event].includes(listener)
    ) {
      this.#listeners[event].push(listener);
    }
  }

  removeEventListener(event, listener) {
    if (this.#events.includes(event)) {
      this.#listeners[event] = this.#listeners[event]?.filter(
        l => l !== listener,
      );
    }
  }

  startClientCall({command, data, callbacks = []}) {
    this._log('Post message', command, data, callbacks);
    if (!isArray(callbacks)) {
      console.error('Invalid callbacks', callbacks);
      return;
    }
    return new Promise((resolve, reject) => {
      const clientCallId = uuidV4();
      this.#clientCalls[clientCallId] = {resolve, reject, callbacks};
      this.#worker.port.postMessage({
        clientCallId,
        command,
        data,
        callbacks: callbacks.map(callback => Boolean(callback)),
      });
    });
  }

  _handleMessage(msg) {
    this._log('Receive worker message', msg);
    if (msg.data.workerCallId) {
      this._handleIncomingWorkerCall(msg);
      return;
    }
    if (msg.data.clientCallId) {
      this._handleClientCallResponse(msg);
      return;
    }
    if (msg.data.event) {
      this._handleEvent(msg);
      return;
    }
    this._log('Unknown message', msg);
  }

  async _handleIncomingWorkerCall(msg) {
    const {command, workerCallId, data} = msg.data;
    try {
      if (!this.#clientAPIs[command]) {
        throw new Error(`Unknown client API: ${command}`);
      }
      const result = await this.#clientAPIs[command](...data);
      this.#worker.port.postMessage({
        workerCallId,
        result,
      });
    } catch (error) {
      this.#worker.port.postMessage({workerCallId, error});
    }
  }

  _handleClientCallResponse(msg) {
    const {clientCallId, callbackId, result, error} = msg.data;
    const call = this.#clientCalls[clientCallId];
    if (!call) {
      return;
    }

    // Handle callback
    if (isNumber(callbackId)) {
      call.callbacks[callbackId]?.(result);
      return;
    }

    // Handle result
    if (error) {
      call.reject(error);
    } else {
      call.resolve(result);
    }
    delete this.#clientCalls[clientCallId];
  }

  _handleEvent(msg) {
    const {event, data} = msg.data;
    if (event === '_APIs') {
      this._populateAPIs(data);
      return;
    }
    for (const listener of this.#listeners[event] || []) {
      listener(event, data);
    }
  }

  async _populateAPIs(data) {
    const {commands, events} = data;
    if (!commands || !events) {
      console.error('Failed to populate worker APIs', data);
    }

    this.#commands = {};
    for (const command of commands) {
      set(this.#commands, command, (...args) => {
        let lastArg = last(args);
        if (isArray(lastArg) && lastArg.every(isFunction)) {
          args.pop();
        } else {
          lastArg = undefined;
        }
        return this.startClientCall({command, data: args, callbacks: lastArg});
      });
    }

    this.#events = events;
    for (const event of this.#events) {
      this.#listeners[event] = [];
    }

    await this.#commands.initClientAPIs?.(Object.keys(this.#clientAPIs));
    this.#inited();
  }

  _log(...args) {
    if (this.#debug) {
      // eslint-disable-next-line no-console
      console.log(...args);
    }
  }
}
