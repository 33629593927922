import {getImageUrl} from '../utils/urls';

function toBackendType(value) {
  switch (value) {
    case 'PRODUCTION':
    case Backend.Type.PRODUCTION:
      return Backend.Type.PRODUCTION;
    case 'STAGING':
    case Backend.Type.STAGING:
      return Backend.Type.STAGING;
    case 'EXPERIMENTAL':
    case Backend.Type.EXPERIMENTAL:
      return Backend.Type.EXPERIMENTAL;
    default:
      return undefined;
  }
}

class Backend {

  static Type = Object.freeze({
    EXPERIMENTAL: Symbol('EXPERIMENTAL'),
    STAGING: Symbol('STAGING'),
    PRODUCTION: Symbol('PRODUCTION'),
  });

  #backend = Backend.Type.PRODUCTION;
  #browserSetting;
  #inited = false;
  #initPromise;

  constructor() {
    this.#initPromise = this._init();
  }

  async init() {
    await this.#initPromise;
  }

  get type() {
    this._checkInit();
    return this.#backend;
  }

  get allTypes() {
    return [
      Backend.Type.EXPERIMENTAL,
      Backend.Type.STAGING,
      Backend.Type.PRODUCTION,
    ];
  }

  get isUsingBrowserSetting() {
    this._checkInit();
    return !!toBackendType(this.#browserSetting);
  }

  get server() {
    this._checkInit();
    switch (this.#backend) {
      case Backend.Type.EXPERIMENTAL:
        return 'https://pinboard-experimental-api.op-test.net/v1';
      case Backend.Type.STAGING:
        return 'https://pinboard-api.op-test.net/v1';
      case Backend.Type.PRODUCTION:
      default:
        return 'https://pinboard.opera-api.com/v1';
    }
  }

  get image() {
    this._checkInit();
    switch (this.#backend) {
      case Backend.Type.EXPERIMENTAL:
        return 'https://pinboard-uploads-experimental.operacdn.com';
      case Backend.Type.STAGING:
        return 'https://pinboard-uploads-staging.operacdn.com';
      case Backend.Type.PRODUCTION:
      default:
        return 'https://pinboard-uploads-production.operacdn.com';
    }
  }

  getImageUrl(path, noCache = false) {
    return getImageUrl(this.image, path, noCache);
  }

  async updateLocalStorageBackendType(value) {
    await this.init();
    const browserBackendType = toBackendType(this.#browserSetting);
    if (browserBackendType) { return; }
    const newType = toBackendType(value);
    if (newType) {
      this.#backend = newType;
      window.localStorage.setItem('backend_type', newType.description);
    }
  }

  async _init() {
    this.#backend = Backend.Type.EXPERIMENTAL;

    if (window.location.host === 'pinboard.opera.com') {
      this.#backend = Backend.Type.PRODUCTION;
    } else if (window.location.host === 'pinboard.op-test.net') {
      this.#backend = Backend.Type.STAGING;
    }

    this._setBackend(window.localStorage.getItem('backend_type'));

    if (window.opr?.pinboardPrivate?.getBackend) {
      this.#browserSetting = await new Promise(resolve => {
          opr.pinboardPrivate.getBackend(resolve);
      });
      this._setBackend(this.#browserSetting);
    }
    this.#inited = true;
  }

  _setBackend(value) {
    const type = toBackendType(value);
    if (type) {
      this.#backend = type;
    }
  }

  _checkInit() {
    console.assert(this.#inited, 'Backend is not fully initialized');
  }
}

export default new Backend();
