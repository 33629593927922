import React from 'react';
import './App.css';
import {Router, Switch, Route} from 'react-router-dom';
import history from './services/history';
import PendingOverlay from './components/PendingOverlay';

const Dashboard = React.lazy(() => import('./views/Dashboard'));
const Pinboard = React.lazy(() => import('./views/Pinboard'));
const BrowserPopup = React.lazy(() => import('./views/BrowserPopup'));
const SaveToPinboard = React.lazy(() => import('./views/SaveToPinboard'));
const Diagnostic = React.lazy(() => import('./views/Diagnostic'));

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <React.Suspense fallback={<PendingOverlay />}>
          <Switch>
            <Route path="/edit/:id/:token">
              <Pinboard editable={true} />
            </Route>
            <Route path="/view/:id">
              <Pinboard editable={false} />
            </Route>
            <Route
              path="/browser-popup/:windowId(\d+)"
              component={BrowserPopup}
            ></Route>
            <Route
              path="/save-to-pinboard/:windowId(\d+)"
              component={SaveToPinboard}
            ></Route>
            <Route path="/diagnostic" component={Diagnostic}></Route>
            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </React.Suspense>
      </Router>
    </div>
  );
}

export default App;
