import React from 'react';
import {IntlProvider} from 'react-intl';
import _ from 'lodash';

import bg from '../lang/bg.json';
import ca from '../lang/ca.json';
import cs from '../lang/cs.json';
import da from '../lang/da.json';
import de from '../lang/de.json';
import el from '../lang/el.json';
import en from '../lang/en.json';
import engb from '../lang/en-GB.json';
import es419 from '../lang/es-419.json';
import fi from '../lang/fi.json';
import fr from '../lang/fr.json';
import hi from '../lang/hi.json';
import hr from '../lang/hr.json';
import hu from '../lang/hu.json';
import id from '../lang/id.json';
import it from '../lang/it.json';
import ja from '../lang/ja.json';
import ko from '../lang/ko.json';
import lt from '../lang/lt.json';
import lv from '../lang/lv.json';
import ms from '../lang/ms.json';
import nb from '../lang/nb.json';
import nl from '../lang/nl.json';
import pl from '../lang/pl.json';
import ptbr from '../lang/pt-BR.json';
import ro from '../lang/ro.json';
import ru from '../lang/ru.json';
import sk from '../lang/sk.json';
import sv from '../lang/sv.json';
import sw from '../lang/sw.json';
import ta from '../lang/ta.json';
import te from '../lang/te.json';
import th from '../lang/th.json';
import tr from '../lang/tr.json';
import uk from '../lang/uk.json';
import vi from '../lang/vi.json';
import zhtw from '../lang/zh-TW.json';
import zhcn from '../lang/zh-CN.json';

const languages = {
  'bg': bg, // TODO: (DNA-94306) Missing emoji
  'ca': ca, // TODO: (DNA-94306) Missing emoji
  'cs': cs, // TODO: (DNA-94306) Missing emoji
  'da': da,
  'de': de,
  'el': el,
  'en': en,
  'en-gb': engb,
  'es': es419, // We lack native spanish translation. Use es-419 for a while.
  'es-419': es419,
  'fi': fi, // TODO: (DNA-94306) Missing emoji
  'fr': fr,
  'hi': hi, // TODO: (DNA-94306) Missing emoji
  'hr': hr,
  'hu': hu, // TODO: (DNA-94306) Missing emoji
  'id': id,
  'it': it,
  'ja': ja,
  'ko': ko, // TODO: (DNA-94306) Missing emoji
  'lt': lt,
  'lv': lv,
  'ms': ms, // TODO: (DNA-94306) Missing emoji
  'nb': nb,
  'nl': nl,
  'pl': pl,
  'pt': ptbr, // We lack native portuguese translation. Use pt-br for a while.
  'pt-br': ptbr, // TODO: (DNA-94306) Missing emoji
  'ro': ro,
  'ru': ru,
  'sk': sk, // TODO: (DNA-94306) Missing emoji
  'sv': sv,
  'sw': sw,
  'ta': ta, // TODO: (DNA-94306) Missing emoji
  'te': te, // TODO: (DNA-94306) Missing emoji
  'th': th,
  'tr': tr, // TODO: (DNA-94306) Missing emoji
  'uk': uk,
  'vi': vi, // TODO: (DNA-94306) Missing emoji
  'zh': zhcn, // zh is zh-cn
  'zh-tw': zhtw,
  'zh-cn': zhcn,
};

const getMessages = locale => {
  locale = locale.toLowerCase();
  if (locale in languages) {
    return _.merge(en, languages[locale]);
  }
  if (locale.substr(0, 2) in languages) {
    return _.merge(en, languages[locale.substr(0, 2)]);
  }
  return en;
};

const IntlContext = props => {
  const locale = props.language || navigator.language;
  const messages = getMessages(locale);

  return (
    <IntlProvider messages={messages} locale={locale}>
      {props.children}
    </IntlProvider>
  );
};

export default IntlContext;
