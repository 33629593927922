import isURL from 'validator/lib/isURL';
import isDataURI from 'validator/lib/isDataURI';

const ORIGIN_URL = `${self.location.protocol}//${self.location.host}`;

export const URLS = {
  origin: ORIGIN_URL,
  dashboard: `${ORIGIN_URL}/`,
};

export function getEditUrl(
  id: string,
  token: string,
  relative = false,
): string {
  const relativeUrl = `/edit/${id}/${token}`;
  return relative ? relativeUrl : `${URLS.origin}${relativeUrl}`;
}

export function getViewUrl(id: string): string {
  return `${URLS.origin}/view/${id}`;
}

export function isDataUrl(url: string): boolean {
  return isDataURI(url);
}

export function isValidWebUrl(url: string, requireProtocol = true): boolean {
  // prettier-ignore
  return isURL(url, {
    'protocols': ['http', 'https'],
    'require_protocol': requireProtocol,
    'require_valid_protocol': true,
    'disallow_auth': true,
  });
}

export function getImageUrl(
  base: string,
  path: string,
  noCache = false,
): string {
  if (!path?.startsWith('images')) {
    return path;
  }
  let url = `${base}/${path}`;
  if (noCache) {
    url += `?no-cache=${Date.now()}`;
  }
  return url;
}
