// Based off of https://gist.github.com/prestomation/24b959e51250a8723b9a5a4f70dcae08
import AWS from 'aws-sdk/global';

function getSignatureKey(key, date, region, service) {
  const kDate = AWS.util.crypto.hmac(`AWS4${key}`, date, 'buffer');
  const kRegion = AWS.util.crypto.hmac(kDate, region, 'buffer');
  const kService = AWS.util.crypto.hmac(kRegion, service, 'buffer');
  const kCredentials = AWS.util.crypto.hmac(kService, 'aws4_request', 'buffer');
  return kCredentials;
}

export function signUrl(host, credentials) {
  // Retrieve YYYYMMDD from date in iso8601 format (YYYY-MM-DD HH:MM:SS)
  const datetime = AWS.util.date
    .iso8601(new Date())
    .replace(/[:-]|\.\d{3}/g, '');
  const date = datetime.substr(0, 8);

  const method = 'GET';
  const protocol = 'wss';
  const uri = '/mqtt';
  const service = 'iotdevicegateway';
  const algorithm = 'AWS4-HMAC-SHA256';
  const region = AWS.config.region;

  const credentialScope = `${date}/${region}/${service}/aws4_request`;
  let canonicalQuerystring = `X-Amz-Algorithm=${algorithm}`;
  canonicalQuerystring += `&X-Amz-Credential=${encodeURIComponent(
    `${credentials.accessKeyId}/${credentialScope}`,
  )}`;
  canonicalQuerystring += `&X-Amz-Date=${datetime}`;
  canonicalQuerystring += '&X-Amz-SignedHeaders=host';

  const canonicalHeaders = `host:${host}\n`;
  const payloadHash = AWS.util.crypto.sha256('', 'hex');
  const canonicalRequest = [
    method,
    uri,
    canonicalQuerystring,
    canonicalHeaders,
    'host',
    payloadHash,
  ].join('\n');
  const stringToSign = [
    algorithm,
    datetime,
    credentialScope,
    AWS.util.crypto.sha256(canonicalRequest, 'hex'),
  ].join('\n');
  const signingKey = getSignatureKey(
    credentials.secretAccessKey,
    date,
    region,
    service,
  );
  const signature = AWS.util.crypto.hmac(signingKey, stringToSign, 'hex');

  canonicalQuerystring += `&X-Amz-Signature=${signature}`;
  if (credentials.sessionToken) {
    canonicalQuerystring += `&X-Amz-Security-Token=${encodeURIComponent(
      credentials.sessionToken,
    )}`;
  }

  const requestUrl = `${protocol}://${host}${uri}?${canonicalQuerystring}`;
  return requestUrl;
}
