import pickerArrow from './arrowLight.png';

const primaryButtonColor = 'var(--primary-button-color)';
const primaryButtonHoverColor = 'var(--primary-button-hover-color)';
const primaryButtonFocusColor = 'var(--primary-button-focus-color)';
const primaryButtonDisabledColor = 'var(--primary-button-disabled-color)';
const primaryButtonFontColor = 'var(--primary-button-font-color)';

export default {
  palette: {
    type: 'light',
    primary: {
      main: '#007acc',
    },
    secondary: {
      main: '#eff4fb',
    },
    error: {
      main: '#fb1e4e',
    },
    background: {
      default: '#fafafa',
      paper: 'white',
      tile: 'white',
    },
    divider: '#e7eef4',
    operaBlue: primaryButtonColor,
    footer: {
      link: '#2a343d',
    },
    action: {
      active: '#ffffff',
      hover: '#e3e5e6',
      selected: '#cdd0d1',
    },
    input: {
      background: '#ffffff',
      border: '#ced4da',
    },
    list: {
      link: '#8997a3',
      subTitle: '#707e8d',
    },
    pinboardsList: {
      buttonSelected: '#EEF3F7',
      dateColor: '#8997A3',
      backgroundColor: 'white',
      backgroundColorHover: '#EEF3F7',
      borderColor: '#D8E2EB',
    },
    separator: {
      default: 'rgba(8, 23, 43, 0.15)',
      hovered: 'rgba(8, 23, 43, 0.35)',
    },
    spinner: {
      defaultColor: 'white',
      thumbnailColor: '#007ACC',
    },
    button: {
      addPinboard: {
        pressed: 'rgba(190, 219, 239, 1)',
        hovered: 'rgba(219, 234, 244, 1)',
      },
      primary: {
        foreground: '#ffffff',
        foregroundDisabled: '#ffffff',
        background: {
          normal: primaryButtonColor,
          hovered: primaryButtonHoverColor,
          pressed: primaryButtonFocusColor,
          disabled: primaryButtonDisabledColor,
        },
      },
      secondary: {
        foreground: '#2a343d',
        background: {
          hovered: 'rgba(0, 122, 204, 0.08)',
          normal: '#ffffff',
          pressed: 'rgba(0, 122, 204, 0.2)',
        },
      },
      border: {
        normal: '#ced1d2',
        hovered: '#ced1d2',
        pressed: '#bec1c2',
      },
      download: {
        background: {
          normal: '#00249C',
          hovered: '#0D2677',
        },
      },
    },
    emojiPicker: {
      background: '#ffffff',
      border: 'rgba(210, 219, 229)',
      emojiPickerArrow: pickerArrow,
    },
    pinboardCard: {
      shadow: '-2px 4px 16px rgba(25, 47, 77, 0.15)',
      foreground: '#2a343d',
      background: '#eff4fb',
      border: '#dae2ea',
    },
    pinboardItem: {
      editMode: {
        border: '2px solid #007ACC',
        boxShadow: '2px 4px 16px rgba(0, 0, 0, 0.15)',
      },
    },
    pinboardPopup: {
      checkmarkIconDisabled: '#DAE2EA',
      selectedPinboard: 'var(--selected-pinboard-color)',
    },
    themeSelector: {
      header: '#536779',
    },
    thumbnailCard: {
      background: primaryButtonDisabledColor,
      button: {
        hovered: 'rgba(0, 122, 204, 0.08)',
      },
      shadow: '1px 3px 8px 4px hsla(255, 0%, 10%, .125)',
    },
    toolbarButton: {
      label: {
        color: '#2a343d',
      },
    },
    Example: {
      header: '#6d7f90',
    },
  },
  overrides: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: '#ffffff',
      },
      root: {
        boxShadow: '0px 4px 5px rgba(150, 173, 255, 0.1)',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: primaryButtonFontColor,
        backgroundColor: primaryButtonColor,
        borderColor: primaryButtonHoverColor,
        '&:hover': {
          backgroundColor: primaryButtonHoverColor,
        },
      },
      containedSecondary: {
        color: 'black',
        backgroundColor: 'white',
        borderColor: '#d8e2eb',
        borderWidth: '1px',
        '&:hover': {
          backgroundColor: 'fafafa',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
        '& $notchedOutline': {
          borderColor: '#ced4da',
        },
        '&:hover $notchedOutline': {
          borderColor: '#ced4da',
        },
        '&$focused $notchedOutline': {
          borderColor: '#ced4da',
        },
      },
    },
    MuiPaper: {
      elevation4: {
        boxShadow: '0px 4px 5px rgba(150, 173, 255, 0.1)',
      },
      elevation6: {
        boxShadow:
          '0px 8px 12px rgba(154, 170, 184, 0.14), 0px 4px 16px rgba(154, 170, 184, 0.12), 0px 4px 6px rgba(154, 170, 184, 0.2)',
      },
      elevation8: {
        boxShadow: '-2px 4px 16px rgba(25, 47, 77, 0.15)',
      },
      root: {
        background: '#FFFFFF',
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: 'white',
        color: '#2A343D',
        boxShadow: '0px 10px 20px -8px rgba(0, 0, 0, 0.24)',
      },
    },
    MuiMenu: {
      paper: {
        background: '#F8FAFA',
        border: '0.5px solid #D1D1D1',
      },
    },
    MuiMenuItem: {
      root: {
        color: 'black',
      },
    },
    MuiNativeSelect: {
      icon: {
        color: '#6A6A74',
      },
    },
    MuiSvgIcon: {
      root: {
        fill: '#2A343D',
      },
    },
    MuiCheckbox: {
      indeterminate: {
        '& .MuiSvgIcon-root': {
          fill: `${primaryButtonColor} !important`,
        },
      },
      colorPrimary: {
        '&:not($checked) .MuiSvgIcon-root': {
          fill: '#6d7f90',
        },
        '&$checked .MuiSvgIcon-root': {
          fill: primaryButtonColor,
        },
      },
    },
  },
};
