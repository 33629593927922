import React from 'react';
import {ThemeProvider} from '@material-ui/core/styles';
import getTheme from '../config/theme';
import {setPaletteColors} from '../config/defaultTheme';

const GxContext = React.createContext();
export function useGxContext() {
  return React.useContext(GxContext);
}

const ThemeContext = props => {
  const [theme, useGxTheme, setUseGxTheme] = getTheme();
  const isGX = props.isGX;

  React.useEffect(() => {
    setPaletteColors(isGX);
    setUseGxTheme(isGX);
  });

  return (
    <GxContext.Provider value={{isGX, useGxTheme, setUseGxTheme}}>
    <ThemeProvider theme={theme}>
      {props.children}
    </ThemeProvider>
    </GxContext.Provider>
  );
};

export default ThemeContext;
